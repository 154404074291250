<template>
  <div class="jinen-box">
    <div class="jinen">
      <!-- 园 -->
      <div class="yuan5"></div>
      <div class="yuan6"></div>

      <!-- 擅长技能 -->
      <div class="scjn-box">
        <div class="content">
          <!-- 进度条 item -->
          <div
            class="bar-box"
            v-for="(i, index) in skillinfo"
            :key="index"
          >
            <div class="box">
              <span>{{i.name}}</span>
              <div class="input">
                <!-- class="blue" -->
                <div :style="{width: i.proficiency + `%`, height: height + 'px', background: background, color: color, borderRadius: borderRadius + 'px', textAlign: textAlign}">
                  {{i.proficiency}} %</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import axios from "axios";

export default {
  name: "SkillNav",
  setup() {
    let skillinfo = ref([]);

    // 样式变量
    let width = ref();
    let height = ref(18);
    let background = ref("rgba(0, 102, 255, 1)");
    let color = ref("#fff");
    let borderRadius = ref(10);
    let textAlign = ref("center");

    onMounted(() => {
      let url = "http://api.hejiu45.top/skillinfo";

      axios
        .get(url)
        .then(function (response) {
          // console.log("技能列表", response.data.data);
          skillinfo.value = response.data.data;
          // console.log("技能", skillinfo.value);
        })
        .catch(function (error) {
          console.log(error);
        });
    });

    return {
      skillinfo,
      width,
      height,
      background,
      color,
      borderRadius,
      textAlign,
    };
  },
};
</script>
<style scoped>
.box .jinen-box {
  width: 100%;
  height: 543px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.box .jinen-box .jinen {
  width: 1211px;
  height: 543px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
}

.box .jinen-box .jinen .yuan5,
.yuan6 {
  background: #06f;
  position: absolute;
  border-radius: 100%;
}

.box .jinen-box .jinen .yuan5 {
  width: 308px;
  height: 308px;
  left: -110px;
  bottom: -130px;
}

.box .jinen-box .jinen .yuan6 {
  width: 308px;
  height: 308px;
  right: -100px;
  top: -100px;
}

.box .jinen-box .jinen .scjn-box {
  width: 513px;
  /* border: 1px solid red; */
}

.box .jinen-box .jinen .scjn-box .content {
  /* width: 100%; */
  /* border: 1px solid red; */
}

.box .jinen-box .jinen .scjn-box .content .bar-box {
  width: 100%;
  /* height: 18px; */
  display: flex;
  align-items: center;
  margin-top: 20px;
  /* border: 1px solid red; */
}

.box .jinen-box .jinen .scjn-box .content .bar-box .box {
  width: 100%;
  height: 18px;
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */
}

.box .jinen-box .jinen .scjn-box .content .bar-box .box span {
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-weight: 400;
  margin-right: 5px;
  /* border: 1px solid red; */
}

.box .jinen-box .jinen .scjn-box .content .bar-box .box .input {
  width: 400px;
  height: 18px;
  background: #f0f3f8;
  border-radius: 10px;
  /* border: 1px solid red; */
}

.box .jinen-box .jinen .scjn-box .content .bar-box .box .input .blue {
  width: 70%;
  height: 18px;
  background: rgba(0, 102, 255, 1);
  color: #fff;
  border-radius: 10px;
  /* border: 1px solid red; */
  text-align: center;
}
</style>