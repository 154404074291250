<template>
    <div class="title-box">
        <div class="box">
            <slot class="title">{{text}}</slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: "TitleNav"
    }
</script>
<style scoped>
    .title-box {
        color: #000;
        width: 100%;
        height: 121px;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 2px solid #06f solid blue; */
    }

    .title-box .box {
        width: 250px;
        height: 50px;
        border-radius: 10px;
        border: 2px solid #06f;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.25);
    }

    .title-box .box p {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
    }
</style>