<template>
  <div class="jieshao-box">
    <div class="jieshao">
      <!-- 园 -->
      <div class="yuan1"></div>
      <div class="yuan2"></div>
      <div class="yuan3"></div>

      <!-- 介绍内容 -->
      <div class="jsc-box">
        <div class="head-img">
          <img
            src="@/assets/head-img/hi00.webp"
            alt="鹤酒的头像"
          >
          <span>{{userinfo.name}}</span>
        </div>
        <div class="content">
          <p>{{userinfo.introduce}}</p>
        </div>
        <div class="shi">
          <p class="content">红尘往事皆为果，谈笑风生一律过人生在世几轮回，平生是否有所为</p>
          <p class="zuozhe">鹤酒作</p>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import axios from "axios";

export default {
  name: "IntroduceNav",
  setup() {
    let userinfo = reactive({
      name: "",
      title: "",
      birthday: "",
      introduce: "",
    });

    onMounted(() => {
      let geturl = "http://api.hejiu45.top/userinfo";
      axios
        .get(geturl)
        .then(function (response) {
          // console.log(response.data.data);
          userinfo.name = response.data.data[0].name;
          userinfo.title = response.data.data[0].title;
          userinfo.birthday = response.data.data[0].birthday;
          userinfo.introduce = response.data.data[0].introduce;
        })
        .catch(function (error) {
          console.log(error);
        });
    });

    return {
      userinfo,
    };
  },
};
</script>
<style scoped>
.box .jieshao-box {
  width: 100%;
  height: 543px;
  /* border: 1px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.box .jieshao-box .jieshao {
  width: 1211px;
  height: 543px;
  /* padding: 0 10px; */
  background: #fff;
  /* border: 1px solid red; */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
}

.box .jieshao-box .jieshao .yuan1,
.yuan2,
.yuan3 {
  background: #00b8a0;
  position: absolute;
  /* left: 0;
    top: 0; */
  border-radius: 100%;
}

.box .jieshao-box .jieshao .yuan1 {
  width: 308px;
  height: 308px;
  left: -200px;
  top: -200px;
}

.box .jieshao-box .jieshao .yuan2 {
  width: 308px;
  height: 308px;
  left: -110px;
  bottom: -130px;
}

.box .jieshao-box .jieshao .yuan3 {
  width: 308px;
  height: 308px;
  right: -100px;
  top: -100px;
}

.box .jieshao-box .jieshao .jsc-box {
  width: 1045px;
  height: auto;
  /* border: 1px solid red; */
  z-index: 1;
}

.box .jieshao-box .jieshao .jsc-box .head-img {
  width: 100%;
  height: 234px;
  /* border: 1px solid blue; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0;
  /* align-items: center; */
}

.box .jieshao-box .jieshao .jsc-box .head-img img {
  height: 150px;
}

.box .jieshao-box .jieshao .jsc-box .head-img span {
  color: rgba(56, 56, 56, 1);
  font-size: 28px;
  font-weight: 600;
  display: block;
  width: 100%;
  height: 30px;
  text-align: center;
}

.box .jieshao-box .jieshao .jsc-box .content {
  width: 100%;
  height: 150px;
  /* border: 1px solid red; */
}

.box .jieshao-box .jieshao .jsc-box .content p {
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-weight: 400;
}

.box .jieshao-box .jieshao .jsc-box .shi {
  /* width: 250px; */
  height: 50px;
  /* border: 1px solid red; */
  position: absolute;
  bottom: 50px;
  right: 45px;
  display: flex;
  align-items: center;
  /* margin-right: 10px; */
  color: rgba(102, 102, 102, 1);
}

.box .jieshao-box .jieshao .jsc-box .shi p {
  color: rgba(102, 102, 102, 1);
  font-size: 14px;
}

.box .jieshao-box .jieshao .jsc-box .shi .content {
  width: 220px;
  height: 30px;
}

.box .jieshao-box .jieshao .jsc-box .shi .zuozhe {
  /* text-align: right; */
  width: 100px;
  height: 15px;
  /* border: 1px solid red; */
}
</style>