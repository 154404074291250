// 引入vuerouter的相关包
import { createWebHashHistory, createRouter } from "vue-router";

// 引入要使用的相关vue组件文件
// 主体
// import App from "@/App.vue";
// 我是谁页面
import MeView from "@/views/MeView/MeView.vue";
// 文章页面
import ArticleView from "@/views/ArticleView/ArticleView.vue";
// 作品页面
import WorksView from "@/views/WorksView/WorksView.vue";

import Test from "@/components/Test/Test";

// 文章列表
import ArtlistView from "@/views/ArtlistView/ArtlistView";

// 文章内容
import ContentView from "@/views/ContentView/ContentView";

// 创建实例化对象
const history = createWebHashHistory();
const router = createRouter({
  history,
  routes: [
    // {
    //   name: "主体页",
    //   path: "/index",
    //   redirect: "/me",
    //   component: App,
    //   children: [
    {
      name: "我是谁",
      path: "/me",
      // redirect: "/me",
      component: MeView,
    },
    {
      name: "文章",
      path: "/article",
      component: ArticleView,
    },
    {
      name: "作品",
      path: "/works",
      component: WorksView,
    },
    {
      name: "test",
      path: "/test",
      component: Test,
    },
    {
      name: "文章列表",
      path: "/artlist",
      component: ArtlistView,
    },
    {
      name: "文章内容",
      path: "/artcontent",
      component: ContentView,
    },
  ],
  //   },
  // ],
});

// 把router路径暴露出去
export default router;
