<template>
  <div class="box">
    <!-- 分类卡片 -->
    <Aintroduce></Aintroduce>

    <!-- 标题 -->
    <TitleNav>
      <span style="color: #000; font-weight: 600;">最近的文章</span>
    </TitleNav>

    <!-- 文章卡片 -->
    <Askill></Askill>

    <!-- 页脚banner -->
    <Dbanner></Dbanner>
  </div>
</template>

<script>
  // 标题组件
  import TitleNav from '@/components/Title/Title'
  // 卡片组件
  import Aintroduce from '@/components/Aintroduce/Aintroduce'
  // 文章卡片
  import Askill from '@/components/Askill/Askill'
  // 页脚banner
  import Dbanner from '@/components/Dbanner/Dbanner'

  export default {
    name: 'ArticleView',
    components: {
      TitleNav,
      Aintroduce,
      Askill,
      Dbanner
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .box {
    width: 1378px;
    height: auto;
    /* border: 1px solid red; */
    background: #f0f3f8;
  }
</style>