<template>
  <div class="box">
    <!-- 自我介绍 -->
    <Introduce></Introduce>

    <Title>
      <span style="color: #000; font-weight: 600;">鹤酒的作品</span>
    </Title>

    <Wskill></Wskill>
  </div>
</template>

<script>
  import Introduce from '@/components/Introduce/Introduce'
  import Title from '@/components/Title/Title'
  import Wskill from '@/components/Wskill/Wskill'

  export default {
    name: 'WorksView',
    components: {
      Introduce,
      Wskill,
      Title
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .box {
    width: 1378px;
    height: auto;
    /* border: 1px solid red; */
    background: #f0f3f8;
  }

  .box .jinen-box {
    width: 100%;
    height: 543px;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    /* border: 1px solid red; */
  }

  .box .jinen-box .jinen {
    width: 1211px;
    height: 543px;
    background: #fff;
    /* border: 1px solid red; */
    border-radius: 10px;
    display: flex;
    justify-content: center;
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    overflow: hidden;
  }

  .box .jinen-box .jinen .yuan5,
  .yuan6 {
    background: #06f;
    position: absolute;
    /* left: 0;
    top: 0; */
    border-radius: 100%;
  }

  .box .jinen-box .jinen .yuan5 {
    width: 308px;
    height: 308px;
    left: -110px;
    bottom: -130px;
  }

  .box .jinen-box .jinen .yuan6 {
    width: 308px;
    height: 308px;
    right: -100px;
    top: -100px;
  }

  .box .jinen-box .jinen .scjn-box {
    /* border: 1px solid blue; */
    width: 513px;

    /* z-index: 2; */

  }

  .box .jinen-box .jinen .scjn-box .content {
    width: 100%;
    /* border: 1px solid red; */
  }

  .box .jinen-box .jinen .scjn-box .content .bar-box {
    width: 100%;
    height: 18px;
    display: flex;
    align-items: center;
    /* border: 1px solid blue; */
    margin-top: 25px;
  }

  .box .jinen-box .jinen .scjn-box .content .bar-box .box {
    width: 100%;
    height: 18px;
    display: flex;
    justify-content: center;
  }

  .box .jinen-box .jinen .scjn-box .content .bar-box .box span {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    font-weight: 400;
    margin-right: 5px;
  }

  .box .jinen-box .jinen .scjn-box .content .bar-box .box .input {
    width: 400px;
    height: 18px;
    /* border: 1px solid red; */
    background: #f0f3f8;
    border-radius: 10px;
  }

  .box .jinen-box .jinen .scjn-box .content .bar-box .box .input .blue {
    width: 70%;
    height: 18px;
    background: rgba(0, 102, 255, 1);
    color: #fff;
    border-radius: 10px;
  }

  .box .jinen-box .boxss {
    border: 1px solid red;
  }
</style>