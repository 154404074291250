<template>
  <div class="list-box">
    <div class="title-box">
      <h2 v-if="art_type === 'frontend'">前端文章</h2>
      <h2 v-if="art_type === 'backend'">后端文章</h2>
      <h2 v-if="art_type === 'bigdata'">大数据文章</h2>
      <h2 v-if="art_type === 'other'">其他文章</h2>
    </div>

    <div class="back-box">
      <button
        class="back"
        @click="goArt"
      >返回文章类目</button>
    </div>

    <div class="list">
      <router-link
        class="item"
        :to="{path: '/artcontent', query:{type: art_type, art_id: i.id}}"
        v-for="(i, index) in art_data"
        :key="index"
      >
        <div class="title">
          <h3>{{i.title}}</h3>
        </div>
        <div class="subtitle">
          <span>{{i.subtitle}}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import axios from "axios";
import router from "@/router";
// 获取pinia数据仓库中的实例
import { mainData } from "@/stores/index";
// 引用pinia中的torefs函数api
import { storeToRefs } from "pinia";

export default {
  name: "ArtlistView",
  setup() {
    let router_info = useRoute();

    let art_type = router_info.query.type;

    let art_data = ref();

    // 获取登录的用户数据
    // 创建实体获取需要使用的变量
    let store = mainData();
    let { searchs } = storeToRefs(store);

    onMounted(() => {
      if (searchs.value != null) {
        art_data.value = searchs.value;
        console.log(art_data.value);
      } else {
        let url = `http://api.hejiu45.top/artinfo/type/${art_type}`;
        axios
          .get(url)
          .then(function (response) {
            // console.log(response);
            // console.log("请求成功！", art_type);
            art_data.value = response.data.data.reverse();
            // console.log(art_data.value);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });

    function goArt() {
      router.push("/article");
    }

    return {
      art_type,
      art_data,
      goArt,
    };
  },
};
</script>
<style scoped>
.list-box {
  width: 1378px;
  padding: 10px;
  background: #fff;
  /* border: 1px solid red; */
}

.list-box .title-box {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  /* border: 1px solid red; */
}

.list-box .title-box h2 {
  position: relative;
  /* border: 1px solid red; */
}

.list-box .title-box h2::before {
  position: absolute;
  content: "";
  width: 90px;
  height: 3px;
  border-radius: 3px;
  background: #06f;
  display: block;
  /* top: 0; */
  left: 0;
  right: 0;
  bottom: -10px;
  margin: auto;
}

.list-box .back-box {
  height: 30px;
  display: flex;
  justify-content: end;
  align-items: center;
  /* border: 1px solid red; */
}

.list-box .back-box button {
  /* background: #06f; */
  border: 2px solid #06f;
  border-radius: 3px;
  height: 30px;
  padding: 0 5px;
  color: #000;
  background: #fff;
}

.list-box .back-box button:hover {
  background: #06f;
  color: #fff;
  transition: 0.5s;
}

.list-box .list {
  /* border: 1px solid red; */
  margin-top: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* border-left: 5px solid #06f; */
}

.list-box .list .item {
  display: block;
  width: 80%;
  /* border: 1px solid red; */
  height: 100px;
  color: #2c3e50;
  border-left: 5px solid #06f;
  padding: 10px;
  background: #efefefd4;
  margin-bottom: 10px;
}

.list-box .list .item .title {
  width: 700px;
  /* border: 1px solid red; */
}

.list-box .list .item .subtitle {
  margin-top: 5px;
  height: 42px;
  overflow: hidden;
  display: -webkit-box;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* border: 1px solid red; */
}
</style>