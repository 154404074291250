<template>
    <div class="title">
        <v-md-editor v-model="text" height="400px"></v-md-editor>
    </div>
</template>
<script>
    import { ref } from "vue"

    export default {
        name: "TestNav",
        setup() {

            // let markdown = ref("::: tip dsa");

            let text = ref("");

            // let html = ref("<h1>mk</h1>");

            return {
                // markdown,
                text,
                // html
            }
        }
    }
</script>
<style scoped>
    .title {
        width: 100%;
        color: #000;
        border: 1px solid red;
    }
</style>