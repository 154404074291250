<template>
  <!-- Banner -->
  <BannerNav></BannerNav>

  <!-- 我的头部 -->
  <HeaderNav></HeaderNav>

  <!-- 身体 -->
  <div class="body-box">
    <router-view></router-view>
  </div>

  <!-- 页脚 -->
  <FooterNav></FooterNav>
</template>

<script>
  import router from "@/router"

  import { onMounted } from "vue"

  import BannerNav from '@/components/Banner/BannerNav';
  import HeaderNav from '@/components/Header/HeaderNav';
  import FooterNav from '@/components/Footer/FooterNav';

  export default {
    name: 'App',
    components: {
      BannerNav,
      HeaderNav,
      FooterNav
    },
    setup() {

      onMounted(() => {
        router.push("/me");
      })

      return {

      }
    }
  }
</script>

<style>
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* color: #fff; */
    text-decoration: none;
    list-style: none;
  }

  html,
  body {
    height: 100%;
    /* background: #f0f3f8; */
    /* overflow: hidden; */
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
    /* text-align: center; */
    color: #2c3e50;
    /* margin-top: 60px; */
    /* height: 100%; */
    width: 100%;
    background: #f0f3f8;
    height: 100%;
  }

  .body-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    background: #f0f3f8;
    /* overflow: hidden; */
  }
</style>