<template>
  <!-- 顶部banner -->
  <div class="banner-box">
    <div class="bc-img-box">
      <div class="title-box">
        <p class="name">{{userinfo.name}}</p>
        <p class="time">{{userinfo.birthday}}</p>
        <p class="text">{{userinfo.title}}</p>
      </div>
    </div>
    <div class="menban"></div>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";
import axios from "axios";
export default {
  name: "BannerNav",
  setup() {
    let userinfo = reactive({
      name: "",
      title: "",
      birthday: "",
      introduce: "",
    });

    onMounted(() => {
      let geturl = "http://api.hejiu45.top/userinfo";
      axios
        .get(geturl)
        .then(function (response) {
          // console.log(response.data.data);
          userinfo.name = response.data.data[0].name;
          userinfo.title = response.data.data[0].title;
          userinfo.birthday = response.data.data[0].birthday;
          userinfo.introduce = response.data.data[0].introduce;
        })
        .catch(function (error) {
          console.log(error);
        });
    });

    return {
      userinfo,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.banner-box {
  width: 100%;
  height: 450px;
  /* border: 1px solid red; */
  position: relative;
  background-image: url(@/assets/bc/banner00.webp);
  background-size: auto;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-box .menban {
  width: 100%;
  height: 450px;
  position: absolute;
  left: 0;
  top: 0;
  /* border: 1px solid blue; */
  background: rgba(0, 0, 0, 0.17);
}

.banner-box .bc-img-box {
  /* width: 448px; */
  height: 147px;
  /* border: 1px solid red; */
  z-index: 3;
}

.banner-box .bc-img-box .title-box {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* z-index: 3; */
}

.banner-box .bc-img-box .title-box p {
  width: 100%;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
}

.banner-box .bc-img-box .title-box .name {
  /* color: rgba(255, 68, 0, 1); */
  color: #06f;

  font-size: 50px;
  /* border: 1px solid red; */
  letter-spacing: 20px;
}

.banner-box .bc-img-box .title-box .name:hover {
  transition: 0.5s;
  text-shadow: 0 0 0px #06f, 0 0 1px #06f, 0 0 10px #06f;
}

.banner-box .bc-img-box .title-box .time {
  margin-top: 12px;
  color: #fff;
}

.banner-box .bc-img-box .title-box .text {
  margin-top: 12px;
  color: #fff;
}
</style>