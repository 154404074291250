<template>
    <div class="db-box">
        <div class="left">
            <div class="title">
                <p>做人如果没梦想，那跟咸鱼有什么区别！</p>
                <p class="zuozhe">--周星星(周星驰)</p>
            </div>


        </div>
        <div class="right">
            <div class="img">
                <img src="@/assets/Dbanner/banner01.webp" alt="">
            </div>
            <div class="text">
                <p>扫一扫，我们就是快乐的小伙伴！</p>
                <a href="https://space.bilibili.com/223916870?spm_id_from=333.1007.0.0">关注我们</a>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "DbannerNav"
    }
</script>
<style scoped>
    .db-box {
        /* width: 100%; */
        height: 350px;
        /* border: 1px solid red; */
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 45px;
        background-image: url(@/assets/Dbanner/banner00.webp);
        display: flex;
        /* border: 1px solid green; */
    }

    .db-box .left {
        width: 689px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid red; */
    }

    .db-box .left .title {
        /* border: 1px solid red; */
        /* width: 500px; */
        border-radius: 2.5px;
        background: #2828286d;
        padding: 0 10px;
        color: #fff;
    }

    .db-box .left .title p {
        font-size: 26px;
    }

    .db-box .left .title .zuozhe {
        display: flex;
        width: 580px;
        margin-top: 10px;
        justify-content: end;
    }

    .db-box .right {
        width: 689px;
        height: 100%;
        /* border: 1px solid red; */
        display: flex;
    }

    .db-box .right .img {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid red; */
    }

    .db-box .right .text {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        /* border: 1px solid red; */
        padding: 120px 0;
        /* color: #fff; */
    }

    .db-box .right .text p {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
    }

    .db-box .right .text a {
        width: 175px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 2.5px;
        border: 2px solid #04b8a0;
        color: #fff;
    }

    .db-box .right .text a:hover {
        /* transform: ; */
        transition: .8s;
        background: #04b8a0;
    }
</style>