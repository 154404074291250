<template>
  <div class="jinen-box">
    <div class="jinen">
      <!-- 园 -->
      <div class="yuan5"></div>
      <div class="yuan6"></div>

      <!-- 擅长技能 -->
      <div class="scjn-box">
        <div class="content">

          <router-link
            :to="{path: '/artcontent', query:{type: null, art_id: i.id}}"
            class="box"
            v-for="(i, index) in data"
            :key="i.id"
          >
            <div class="icon">
              <img
                src="@/assets/icon/01.png"
                alt=""
              >
              <span>{{i.title}}</span>
            </div>
            <div class="text">
              <p>{{i.subtitle}}</p>
            </div>
          </router-link>

        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import axios from "axios";
import router from "@/router";

export default {
  name: "SkillNav",
  setup() {
    let data = ref([]);

    onMounted(async () => {
      let url = "http://api.hejiu45.top/artinfo";
      let datas = [];

      await axios
        .get(url)
        .then(function (response) {
          // console.log(response.data.data);
          let data = response.data.data.reverse();

          for (let i = 0; i < 4; i++) {
            datas.push(data[i]);
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      data.value = datas;

      // console.log("content", data.value);
    });

    return {
      data,
    };
  },
};
</script>
<style scoped>
.box .jinen-box {
  width: 100%;
  height: 543px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.box .jinen-box .jinen {
  width: 1211px;
  height: 543px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
}

.box .jinen-box .jinen .yuan5,
.yuan6 {
  background: #06f;
  position: absolute;
  border-radius: 100%;
}

.box .jinen-box .jinen .yuan5 {
  width: 308px;
  height: 308px;
  left: -110px;
  bottom: -130px;
}

.box .jinen-box .jinen .yuan6 {
  width: 308px;
  height: 308px;
  right: -100px;
  top: -100px;
}

.box .jinen-box .jinen .scjn-box {
  width: 810px;
  z-index: 3;
}

.box .jinen-box .jinen .scjn-box .content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 65px 0;
  /* border: 1px solid red; */
}

.box .jinen-box .jinen .scjn-box .content .box {
  border: 2px solid #ccc;
  width: 400px;
  height: 200px;
  display: block;
  background: #fff;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.box .jinen-box .jinen .scjn-box .content .box .icon {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  padding-left: 10px;
}

.box .jinen-box .jinen .scjn-box .content .box .icon img {
  width: 40px;
  /* border: 1px solid red; */
}

.box .jinen-box .jinen .scjn-box .content .box .icon span {
  color: #000;
  /* width: 250px; */
  height: 30px;
  /* border: 1px solid rebeccapurple; */
  font-size: 22px;
  font-weight: 600;
  margin-left: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.box .jinen-box .jinen .scjn-box .content .box .text {
  /* border: 1px solid red; */
  width: 100%;
  height: 152px;
  padding: 10px 20px;
}

.box .jinen-box .jinen .scjn-box .content .box .text p {
  color: #000;
  width: 360px;
  height: 125px;
  text-indent: 2em;
  overflow: hidden;
  /* 超出部分隐藏 */
  /* white-space: nowrap; */
  /* 不换行 */
  /* text-overflow: ellipsis; */
  /* 将超出部分替换为省略号 */
  display: -webkit-box;
  /* 使用webkit内核的弹性布局 */
  -webkit-line-clamp: 6;
  /* 显示三行文本 */
  -webkit-box-orient: vertical;
  /* 文本垂直排列 */
}
</style>