<template>
  <div class="footer-box">
    <div class="text">鹤酒的空间永远不会消失</div>
  </div>
</template>

<script>
  export default {
    name: 'FooterNav',
  }
</script>

<style scoped>
  .footer-box {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(56, 56, 56, 1);
  }

  .footer-box .text {
    font-size: 30px;
    font-weight: 400;
    color: #fff;
  }
</style>