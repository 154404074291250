import { defineStore } from "pinia";

export const mainData = defineStore("main", {
  state: () => {
    return {
      uname: null,
      // upasswd: null,
      iftf_user: false,
      webTitle: "鹤酒管理系统",
      searchs: null,
    };
  },
});
