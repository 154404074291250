import { createApp } from "vue";
import App from "./App.vue";

// 引入pinia
import { createPinia } from "pinia";

// import * as echarts from "echarts";

// 引入axios
// import axios from "axios";

// 引入naive ui
import naive from "naive-ui";

// 引入naive ui的字体
// 通用字体
import "vfonts/Lato.css";
// 等宽字体
import "vfonts/FiraCode.css";

import router from "./router";

// md文档工具引入
// import VueMarkdownEditor from "@kangc/v-md-editor";
// import "@kangc/v-md-editor/lib/style/base-editor.css";
// import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
// import "@kangc/v-md-editor/lib/theme/style/vuepress.css";

// import Prism from "prismjs";
// import "@kangc/v-md-editor/lib/plugins/highlight-lines/highlight-lines.css";

// VueMarkdownEditor.use(vuepressTheme, {
//   Prism,
//   extend(md) {
//     // md为 markdown-it 实例，可以在此处进行修改配置,并使用 plugin 进行语法扩展
//     // md.set(option).use(plugin);\
//     return md;
//   },
// });

// import createHighlightLinesPlugin from "@kangc/v-md-editor/lib/plugins/highlight-lines/index";
import VMdEditor from "@kangc/v-md-editor/lib/codemirror-editor";
import "@kangc/v-md-editor/lib/style/codemirror-editor.css";
import githubTheme from "@kangc/v-md-editor/lib/theme/github.js";
import "@kangc/v-md-editor/lib/theme/style/github.css";

// highlightjs
import hljs from "highlight.js";

// codemirror 编辑器的相关资源
import Codemirror from "codemirror";
// mode
import "codemirror/mode/markdown/markdown";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/mode/vue/vue";
// edit
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/edit/closetag";
import "codemirror/addon/edit/matchbrackets";
// placeholder
import "codemirror/addon/display/placeholder";
// active-line
import "codemirror/addon/selection/active-line";
// scrollbar
import "codemirror/addon/scroll/simplescrollbars";
import "codemirror/addon/scroll/simplescrollbars.css";
// style
import "codemirror/lib/codemirror.css";

VMdEditor.Codemirror = Codemirror;
VMdEditor.use(githubTheme, {
  Hljs: hljs,
});

import createLineNumbertPlugin from "@kangc/v-md-editor/lib/plugins/line-number/index";

import createEmojiPlugin from "@kangc/v-md-editor/lib/plugins/emoji/index";
import "@kangc/v-md-editor/lib/plugins/emoji/emoji.css";

import createTipPlugin from "@kangc/v-md-editor/lib/plugins/tip/index";
import "@kangc/v-md-editor/lib/plugins/tip/tip.css";

import createCopyCodePlugin from "@kangc/v-md-editor/lib/plugins/copy-code/index";
import "@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css";

VMdEditor.use(createCopyCodePlugin());

VMdEditor.use(createEmojiPlugin());
VMdEditor.use(createLineNumbertPlugin());
VMdEditor.use(createTipPlugin());

createApp(App)
  .use(createPinia())
  //.use(axios)
  .use(naive)
  .use(router)
  .use(VMdEditor)
  // .use(VueMarkdownEditor)
  .mount("#app");
