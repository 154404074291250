<template>
  <div class="content-box">
    <div class="back-box">
      <button @click="back">返回文章列表</button>
    </div>

    <div class="box">
      <v-md-editor
        v-model="text"
        mode="preview"
        class="md"
      ></v-md-editor>
    </div>

    <div class="time-box">
      <span>{{time}}</span>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import axios from "axios";
import router from "@/router";

export default {
  name: "ContentView",
  setup() {
    let text = ref("");

    let router_info = useRoute();

    let id = router_info.query.art_id;

    let art_type = router_info.query.type;

    let time = ref();

    onMounted(() => {
      let url = `http://api.hejiu45.top/artinfo/${id}`;
      axios
        .get(url)
        .then(function (response) {
          // console.log(response.data.data);
          // console.log(id);
          time.value = response.data.data.created;
          text.value = response.data.data.texts;
        })
        .catch(function (error) {
          console.log(error);
        });

      // console.log(art_type);
    });

    function back() {
      router.push(`/artlist?type=${art_type}`);
    }

    return {
      text,
      time,
      back,
    };
  },
};
</script>
<style scoped>
.content-box {
  width: 1378px;
  /* height: 300px; */
  /* border: 1px solid red; */
  /* background: #fff; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.back-box {
  width: 1378px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  /* border: 1px solid red; */
  background: #fff;
}

.back-box button {
  padding: 5px;
  border: none;
  border: 2px solid #06f;
  background: #fff;
  color: #000;
  border-radius: 3px;
}

.back-box button:hover {
  transition: 0.3s;
  color: #fff;
  background: #06f;
}

.content-box .box {
  /* border: 1px solid red; */
  /* width: 1000px; */
  width: 100%;
  padding: 0 50px;
  background: #fff;
  /* background: #f0f3f8; */
  margin-top: 10px;
}

.time-box {
  width: 1378px;
  /* border: 1px solid red; */
  height: 50px;
  background: #fff;
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.time-box span {
  color: #666;
}
</style>