<template>
  <div class="header-box">
    <div class="header">
      <div class="menu-box">
        <router-link :class="index === act_index ? actxia : defxia" :to="i.path" v-for="(i, index) in menuData"
          :key="index" @click="actfun(i, index)">{{i.name}}</router-link>
      </div>

      <div class="search-box">
        <input class="search" v-model="inputValue" placeholder="搜索文章名称..." type="text" @keyup.enter="search">
      </div>
    </div>

  </div>
</template>

<script>
  import { ref, reactive, onMounted } from "vue"
  import axios from "axios"
  import router from "@/router"
  // 获取pinia数据仓库中的实例
  import { mainData } from "@/stores/index"
  // 引用pinia中的torefs函数api
  import { storeToRefs } from "pinia"

  export default {
    name: 'HeaderNav',
    setup() {
      let act_index = ref(0)

      let inputValue = ref(null)

      // 获取登录的用户数据
      // 创建实体获取需要使用的变量
      let store = mainData();
      let { searchs } = storeToRefs(store);

      let actxia = ref("act_xia")
      let defxia = ref("def_xia")

      let menuData = reactive([
        {
          id: 1,
          name: "我是谁",
          path: "/me"
        },
        {
          id: 2,
          name: "文章",
          path: "/article"
        },
        {
          id: 3,
          name: "作品",
          path: "/works"
        },
      ])

      onMounted(() => {

      })

      // 菜单激活方法
      function actfun(i, index) {
        act_index.value = index
      }

      // 回车搜索方法
      function search() {
        // console.log("搜索触发成功");
        // console.log(inputValue.value);

        let url = `http://39.101.77.243:89/artinfo/search/${inputValue.value}`

        axios.get(url)
          .then(function (response) {
            searchs.value = response.data.data
            // console.log(searchs.value);
            router.push("/artlist")
          })
          .catch(function (error) {
            console.log(error);
          })
      }

      return {
        menuData,
        actfun,
        act_index,
        actxia,
        defxia,
        search,
        inputValue
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .header-box {
    width: 100%;
    height: 60px;
    border-bottom: 2px solid #06f;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-box .header {
    width: 1378px;
    /* border: 1px solid red; */
    display: flex;
    height: 100%;
    /* justify-content: center; */
    padding: 0 80px;
    align-items: center;
  }

  .header-box .menu-box {
    width: 232px;
    height: 33px;
    /* border: 1px solid red; */
    /* text-align: center; */
    flex: 1;
  }

  .header-box .search-box {
    height: 33px;
    flex: 0.5;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .header-box .search-box .search {
    height: 28px;
    border: 1px solid #eee;
    padding: 5px;
    border-radius: 3px;
    outline: none;
    background: #f0f3f8;
  }

  .header-box .menu-box a {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    padding: 0 10px;

  }

  .header-box .menu-box .def_xia {}

  .header-box .menu-box .act_xia::before {
    content: "";
    display: block;
    width: 36px;
    height: 4px;
    background: #06f;
    border-radius: 10px;
    left: 0;
    top: 26px;
    right: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
  }

  /* .header-box .menu-box .xia:active::before {
    content: "";
    display: block;
    width: 36px;
    height: 4px;
    background: #06f;
    border-radius: 10px;
    left: 0;
    top: 26px;
    right: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
  } */

  /* .header-box .menu-box .xia:hover::before {
    content: "";
    display: block;
    width: 36px;
    height: 4px;
    background: #06f;
    border-radius: 10px;
    left: 0;
    top: 26px;
    right: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
  } */
</style>